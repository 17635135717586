import { inject, injectable } from 'inversify';
import { mergeDeepRight } from 'ramda';
import {
  BehaviorSubject,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';

import { ACCOUNT_TYPES } from '@/ioc/types';

import {
  IAccountEntity,
  IAccountRepository,
  IAccountSettingsEntity,
  IProvideGoogleContactsReqEntity,
} from '../domain';

import { IAccountDao } from './db/dao/AccountDao';
import { IAccountServicesApi } from './network/AccountApiServices';
import { COUNTRIES_DC, IAccountDC } from './dataContracts';
import {
  mapAccountDcToEntity,
  mapAccountEntityToDc,
  mapGoogleContactsReqEntityToDC,
  mapOnboardingEntityKey,
} from './mappers';

@injectable()
export default class AccountRepository implements IAccountRepository {
  @inject(ACCOUNT_TYPES.AccountDao)
  private accountDao: IAccountDao;

  @inject(ACCOUNT_TYPES.AccountApiService)
  private accountServiceApi: IAccountServicesApi;

  private emailForAccountCreationBehaviorSubject: BehaviorSubject<string> =
    new BehaviorSubject('');

  saveEmailForAccountCreation(email: string): Observable<void> {
    this.emailForAccountCreationBehaviorSubject.next(email);
    return of(undefined);
  }

  deleteEmailForAccountCreation(): Observable<void> {
    this.emailForAccountCreationBehaviorSubject.next('');
    return of(undefined);
  }

  getEmailForAccountCreation(): BehaviorSubject<string> {
    return this.emailForAccountCreationBehaviorSubject;
  }

  getAccount(): Observable<IAccountEntity | null> {
    return this.accountDao.getCurrent().pipe(
      map((acc: IAccountDC | null) => {
        return acc ? mapAccountDcToEntity(acc) : null;
      }),
    );
  }

  syncAccount(email?: string): Observable<void> {
    return this.accountServiceApi.syncAccount(email);
  }

  setupAccount(account: {
    fullName: string;
    phone: string;
    country: COUNTRIES_DC;
  }): Observable<void> {
    return this.accountServiceApi
      .updateAccount({
        full_name: account.fullName,
        settings: {
          country: account.country,
          phone: account.phone,
        },
      })
      .pipe(map(() => undefined));
  }

  updateAccount(accountPatch: DeepPartial<IAccountEntity>): Observable<void> {
    return this.getAccount().pipe(
      first(),
      switchMap((account) => {
        if (!account) {
          return throwError(() => new Error('Account is not initialized'));
        }

        const updatedAccount = mergeDeepRight(account, accountPatch);

        return this.accountDao
          .upsert(mapAccountEntityToDc(updatedAccount))
          .pipe(map(() => void 0));
      }),
    );
  }

  updateSettings(settings: Partial<IAccountSettingsEntity>): Observable<void> {
    return this.updateAccount({
      settings,
    });
  }

  async completeOnboardingStep(
    step: keyof IAccountEntity['settings']['onboarding'],
  ): Promise<void> {
    await firstValueFrom(
      this.accountServiceApi.updateSettings({
        onboarding: { [mapOnboardingEntityKey(step)]: true },
      }),
    );
  }

  async provideContacts(req: IProvideGoogleContactsReqEntity): Promise<void> {
    await firstValueFrom(
      this.accountServiceApi.provideContacts(mapGoogleContactsReqEntityToDC(req)),
    );
  }

  deleteAccount(): Observable<void> {
    return this.accountServiceApi.deleteAccount().pipe(map(() => void 0));
  }
}
