import { type RxJsonSchema } from 'rxdb';

import { COUNTRIES_DC } from '../../dataContracts';
import { type IAccountDC } from '../../dataContracts/AccountDC';

export const accountSchema: RxJsonSchema<IAccountDC> = {
  title: 'account schema',
  version: 5,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid', 'email', 'last_activity', 'settings', 'role'],
  properties: {
    uuid: { type: 'string', maxLength: 100 },
    email: { type: 'string' },
    admin_email: { type: 'string' },
    role: { type: 'string' },
    full_name: { type: 'string' },
    thumbnail: { type: 'string' },
    last_activity: { type: 'string' },
    settings: {
      type: 'object',
      properties: {
        country: { type: 'string', enum: Object.values(COUNTRIES_DC) },
        extension_position: { type: 'number' },
        open_automatically: { type: 'boolean' },
        phone: { type: 'string' },
        is_email_verified: { type: 'boolean' },
        has_tried_to_uninstall_extension: { type: 'boolean' },
        got_retension_credits: { type: 'boolean' },
        language: { type: 'string' },
        onboarding: {
          type: 'object',
          properties: {
            is_contact_exported: { type: 'boolean' },
            is_contact_revealed: { type: 'boolean' },
            is_extension_installed: { type: 'boolean' },
            is_team_member_invited: { type: 'boolean' },
            is_signup_complete: { type: 'boolean' },
            is_viewed_install_extension_page: { type: 'boolean' },
            is_viewed_dashboard: { type: 'boolean' },
            is_clicked_download_extension: { type: 'boolean' },
            mobile_signup_open_chrome_store: { type: 'boolean' },
          },
        },
      },
    },
    updated_at: { type: 'number' },
    created_at: { type: 'number' },
    unconfirmed_email: { type: 'string' },
    providers: { type: 'string' },
    total_searches_count: { type: 'number' },
    total_credits_used: { type: 'number' },
    current_search_count: { type: 'number' },
    current_credits_used: { type: 'number' },
    personal_workspace_id: { type: 'string' },
    referrer_token: { type: 'string' },
    referrer_credits_earned: { type: 'number' },
    is_invited: { type: 'boolean' },
  },
};
