import { inject, injectable } from 'inversify';
import { from, Observable, switchMap, tap } from 'rxjs';

import { AUTH_TYPES, ONBOARDING_TYPES } from '@/ioc/types';

import type { IAuthRepository } from '@/features/common/auth';
import type { IOnboardingUseCase } from '@/features/common/onboarding';
import type { ILoginUseCases } from '@/features/signin/domain';

@injectable()
export class LoginUseCases implements ILoginUseCases {
  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(ONBOARDING_TYPES.OnboardingUseCase)
  private onboardingUseCase: IOnboardingUseCase;

  loginGoogle(): Observable<unknown> {
    return this.authRepository
      .signInWithGoogle()
      .pipe(tap(() => this.completeOnboardingSteps()));
  }

  loginMicrosoft(): Observable<unknown> {
    return this.authRepository
      .signInWithMicrosoft()
      .pipe(tap(() => this.completeOnboardingSteps()));
  }

  loginWithEmailAndPass(email: string, password: string): Observable<unknown> {
    return this.authRepository
      .signInWithEmailAndPassword(email, password)
      .pipe(tap(() => this.completeOnboardingSteps()));
  }

  private async completeOnboardingSteps(): Promise<void> {
    try {
      await this.onboardingUseCase.completeMobileSignupOpenChromeStoreStep();
    } catch {}
  }

  sendVerificationLinkForRestorePassword(email: string): Observable<unknown> {
    return this.authRepository.sendPasswordResetEmail(email);
  }

  loginWithCustomToken(customToken: string): Observable<unknown> {
    return this.authRepository.signInWithCustomToken(customToken);
  }
}
