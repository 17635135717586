const SAVED_CONTACTS_ROOT = '/saved-contacts';
const ACCOUNT_ROOT = '/account';
const SIGN_UP_ROOT = '/signup';
const INTEGRATIONS = '/integrations';
const SIGN_IN_ROOT = '/sign-in';
const ENRICHMENT_ROOT = '/enrichment';

export const ROUTES = {
  DASHBOARD: '/',
  SETTINGS: {
    ACCOUNT: ACCOUNT_ROOT,
    INDEX: ACCOUNT_ROOT,
    TEAM_MANAGEMENT: `${ACCOUNT_ROOT}/team-management`,
    SUBSCRIPTION: `${ACCOUNT_ROOT}/subscription`,
    TAG_MANAGEMENT: `${ACCOUNT_ROOT}/tags`,
    REFERRAL: `${ACCOUNT_ROOT}/refer-a-friend`,
  },
  BILLING: {
    PLANS: '/our-plans',
    PAYMENT_DETAILS: '/payment-details',
  },
  INTEGRATIONS: INTEGRATIONS,
  INTEGRATION: `${INTEGRATIONS}/:id`,
  CONTACTS_ENTRY: SAVED_CONTACTS_ROOT,
  CONTACTS_INNER: `${SAVED_CONTACTS_ROOT}/:id`,
  ENRICHMENT_ENTRY: ENRICHMENT_ROOT,
  ENRICHMENT_REPORT: `${ENRICHMENT_ROOT}/:id`,
  PLANS: '/plans',
  ERROR_500: '/500',
  WILDCARD: '*',
  SIGN_UP: SIGN_UP_ROOT,
  SIGN_UP_REDIRECT_SUCCESS_VERIFICATION: `${SIGN_UP_ROOT}/new/verification/success`,
  SIGN_UP_VERIFICATION: `${SIGN_UP_ROOT}/new/verification`,
  SIGN_UP_NEW: `${SIGN_UP_ROOT}/new`,
  SIGN_UP_INSTALL_EXTENSION: `${SIGN_UP_ROOT}/new/install-extension`,
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SAVED_CONTACTS: '/contacts',
  LOGIN: SIGN_IN_ROOT,
  LOGIN_WITH_TOKEN: `${SIGN_IN_ROOT}/token`,
  EMAIL_LINKS_LANDER: `${SIGN_UP_ROOT}/success`,
  LEAVING_SOON: `leaving-soon`,
  REFERRALS: '/referrals/:token',
};

const getMarketingRoute = (path: string): string => {
  return `${import.meta.env.REACT_APP_POWERLEAD_MARKETING_SITE_DOMAIN}${path}`;
};

export const EXTERNAL_ROUTES = {
  HELP_CENTER: `https://help.powerlead.com`,
  TERMS: getMarketingRoute(`/terms`),
  PRIVACY: getMarketingRoute(`/privacy-policy`),
  TALK_TO_SALES: getMarketingRoute(`/contact-us?topic=sales`),
  REQUEST_DEMO: getMarketingRoute(`/get-my-demo`),
  EXTENSION_URL: import.meta.env.REACT_APP_EXTENSION_URL,
  LINKEDIN_URL: 'https://www.linkedin.com/search/results/people/',
  LINKEDIN_PROFILE_TO_REVEAL: 'https://www.linkedin.com/in/danreynolds11/',
  HELP_CENTER_FAIR_USAGE_POLICY:
    'https://help.powerlead.com/en/articles/9921507-powerlead-fair-use-policy',
};
