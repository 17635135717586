import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

import { IAccountDC, IAccountSettingsDC } from '../dataContracts/AccountDC';
import { IProvideGoogleContactsReqDC } from '../dataContracts/ProvideGoogleContactsReqDC';

export interface IAccountServicesApi {
  fetchAccount(): Observable<IAccountDC>;

  syncAccount(email?: string): Observable<void>;

  updateSettings(reqBody: DeepPartial<IAccountSettingsDC>): Observable<IAccountDC>;

  updateAccount(payload: DeepPartial<IAccountDC>): Observable<IAccountDC>;

  deleteAccount(): Observable<boolean>;

  provideContacts(req: IProvideGoogleContactsReqDC): Observable<boolean>;
}

const accountEndpoints = {
  account: 'account',
  provideContacts: 'account/fetch_contacts',
  account_delete: 'debug/delete_account',
  sync: 'account/sync',
};

@injectable()
export default class AccountApiService implements IAccountServicesApi {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  fetchAccount(): Observable<IAccountDC> {
    return this.httpClient
      .get(`/account`)
      .pipe(map((response: AxiosResponse<IAccountDC>) => response.data));
  }

  syncAccount(email?: string): Observable<void> {
    let syncEndpoint = accountEndpoints.sync;

    if (email) {
      syncEndpoint = `${syncEndpoint}?email=${email}`;
    }

    return this.httpClient.post(syncEndpoint, {}).pipe(map(() => void 0));
  }

  updateSettings(reqBody: IAccountSettingsDC): Observable<IAccountDC> {
    return this.httpClient
      .patch('/account/settings', reqBody)
      .pipe(map((res: AxiosResponse<IAccountDC>) => res.data));
  }

  deleteAccount(): Observable<boolean> {
    return this.httpClient
      .post(accountEndpoints.account_delete, {})
      .pipe(map((res: AxiosResponse<boolean>) => res.data));
  }

  updateAccount(account: Partial<IAccountDC>): Observable<IAccountDC> {
    return this.httpClient
      .patch(accountEndpoints.account, account)
      .pipe(map((response: AxiosResponse<IAccountDC>) => response.data));
  }

  provideContacts(req: IProvideGoogleContactsReqDC): Observable<boolean> {
    return this.httpClient
      .post(accountEndpoints.provideContacts, req)
      .pipe(map((r: AxiosResponse<boolean>) => r.data));
  }
}
